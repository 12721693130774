<template>
  <v-row id="panel-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="savePanel">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12 col-lg-6 col-md-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Panel Group</v-subheader>
                            <v-autocomplete ref="panel_group_id" v-model="model.panel_group_id" :items="panelGroupsList" outlined label=""
                                :error-messages="$helpers.errorMsg('name', $v.model.panel_group_id, 'Panel Group')" placeholder="Select Panel Group"
                                @input="$v.model.panel_group_id.$touch()"  @blur="$v.model.panel_group_id.$touch()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12 col-lg-6 col-md-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-subheader>
                            <v-text-field  ref="name" v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'Name')"
                                placeholder="Name" outlined @input="$v.model.name.$touch()"  @blur="$v.model.name.$touch()" required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12 col-lg-6 col-md-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Label</v-subheader>
                            <v-text-field  ref="label" v-model="model.label" :error-messages="$helpers.errorMsg('name', $v.model.label, 'Label')"
                                placeholder="Label" outlined @input="$v.model.label.$touch()"  @blur="$v.model.label.$touch()" required/>
                        </v-col>
                        <v-col cols="12 col-lg-6 col-md-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Position</v-subheader>
                            <v-text-field type="number" ref="position" v-model.number="model.position"
                                :error-messages="$helpers.errorMsg('number', $v.model.position, 'Position')"
                                placeholder="Position" outlined @input="$v.model.position.$touch()"  @blur="$v.model.position.$touch()" required/>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12 col-lg-6 col-md-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Type</v-subheader>
                            <v-select ref="type"  v-model="model.type" :items="typeList" outlined label=""
                                :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}" 
                                :error-messages="$helpers.errorMsg('name', $v.model.type, 'Type')" placeholder="Select Type"
                                @input="$v.model.type.$touch()"  @blur="$v.model.type.$touch()" required>
                            </v-select>
                        </v-col>
                        <v-col cols="12 col-lg-6 col-md-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Status</v-subheader>                            
                            <v-switch v-model="model.status" inset label="" :true-value="1" :false-value="0"></v-switch>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" outlined color="primary" class="mr-4" @click.native="$router.push('/panels')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from 'vuex';

export default {
    data: (vm) => ({
        panelId: vm.$route.params.panelId,
        model: {
            name: '',
            status: 1,
            type: 'D'
        },
        typeList: [{text: 'Default', value: 'D'}, {text: 'Large', value: 'L'}, {text: 'Extra Large', value: 'XL'}]
    }),
    validations() {
        return {
            model: {
                panel_group_id: { required},
                name: { required},
                label: { required},
                type: { required},
                position: { required, minValue: minValue(1)},
            }
        }
    },
    computed: {
        ...mapGetters(['panelGroupsList'])
    },
    created() {
        if (this.panelId != 'create') {
          this.getSelectedPanel(this.panelId).then(response => this.model = response);
        }
        this.getPanelGroupLists();
    },
    methods: {
        ...mapActions(['getSelectedPanel', 'createPanel', 'updateSelectedPanel', 'getPanelGroupLists']),

        savePanel() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            if (this.panelId != 'create') {
                this.updateSelectedPanel(this.model).then(resp => this.$router.push("/panels"));
            } else {
                this.createPanel(this.model).then(resp => this.$router.push("/panels"));
            }
        },
    },
};
</script>
